<template>
    <v-col cols="12" class="pa-0" id="rims-architecture-page">
      <v-skeleton-loader :loading="true" style="height: 530px;" v-if="metaArchLoading || scanLoading" type="image">
      </v-skeleton-loader>
      <v-card class="position-relative mb-2" v-if="!metaArchLoading && !scanLoading" max-height="500">
        <div class="py-5">
          <vue-perfect-scrollbar :settings="{ suppressScrollX: true, wheelPropagation: false }" class="ps"
              style="height: 450px">
            <!-- <template v-for="(row, index) in tireData"> -->
                <!-- <div>rims</div> -->
            <!-- </template> -->
            <v-row class="ma-0">
                <v-col cols="3">
                    <v-card tile class="text-center">
                        <span class="pt-2">Rear Left:</span>
                        <v-card-text class="align-center justify-center d-flex pa-0">
                            <v-lazy>
                                <img height="100" width="100" contain :id="`annoatate`" :src="image" class="ma-2 cursor-pointer"
                                    crossorigin="anonymous"/>
                            </v-lazy>
                        </v-card-text>
                        <v-card-actions class="d-flex flex-wrap align-center justify-space-between px-4">
                            <v-icon :color="color" :style="`color: ${textColor}`" class="mr-2 cursor-pointer" @click="show(image)">
                                mdi-archive-eye-outline
                            </v-icon>
                            <v-icon :color="color" :style="`color: ${textColor}`" class="mr-2 cursor-pointer" @click="openCrop = image">
                                mdi-crop
                            </v-icon>
                            <v-icon :color="color" :style="`color: ${textColor}`" class="mr-2 cursor-pointer" @click="showMarkerArea(`annoatate`)">
                                mdi-image-plus
                            </v-icon>
                            <v-icon :color="color" :style="`color: ${textColor}`" class="mr-2 cursor-pointer">
                                mdi-information
                            </v-icon>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <CropperModal :openCrop="openCrop" v-if="!scanLoading && openCrop" @cancel="openCrop = null"
                @cropped="$emit('editedAnnotate', $event); openCrop = null" :name="'Cropped'"/>
          </vue-perfect-scrollbar>
        </div>
      </v-card>
    </v-col>
  </template>

<script>
import {mapGetters} from 'vuex';
import _ from 'lodash';
import * as markerjs2 from "markerjs2";

export default {
    props: ['model', 'color', 'textColor'],
    emits: ['editedAnnotate'],
    components: {
        CropperModal: () => import("./CropperModal.vue"), 
    },
    data: (vm) => ({
        rimsData: null,
        image: 'https://portal.scanngo.com.au/img/1.a305ebd2.png',
        openCrop: null,
    }),
    watch: {
        model(value) {
            if (value) this.rimsData = [];
        }
    },
    created() {
        if (this.model) this.rimsData = [];
    },
    computed: {
        ...mapGetters(['scanLoading', 'metaArchLoading'])
    },
    methods: {
        show(value) {
            const image = [];
            image.push(value);
            this.$viewerApi({
                images: image
            })
        },
        showMarkerArea(imgId) {
            const target = document.getElementById(imgId);
            const markerArea = new markerjs2.MarkerArea(target);
            markerArea.uiStyleSettings.zoomOutButtonVisible = true;
            markerArea.uiStyleSettings.zoomButtonVisible = true;
            markerArea.renderImageType = 'image/jpeg';
            markerArea.settings.displayMode = "popup";
            markerArea.addEventListener("render", (event) => {
                this.$store.commit('setPageLoading', false);
                this.$emit('editedAnnotate', {value: event.dataUrl, option: 'Annotated'});
            });
            markerArea.show();
        },
    }
}
</script>
